import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import ScannerBody from "../components/scannerScreen/scannerBody";
import {
  EComponent,
  executeCancelCommand,
  getStatus,
  parseResponse,
  scan,
} from "../services/kiosk";
import { ROUTES } from "../constants/routes";
import { useNavigate } from "react-router-dom";

function ScannerScreen() {
  const [isScanningBarcode, setIsScanningBarcode] = useState(false);
  const navigate = useNavigate();

  // TODO uncomment this to test transaction screen with data start
  // const purchaseOrderId = `66fe9010d889913bdfbf3759`;

  const onTimerFinish = useCallback(() => {
    window.location.href = ROUTES.HOMEPAGE.path;
  }, []);

  const startScanner = useCallback(async () => {
    try {
      console.log("Starting scanner...");

      const res = await executeCancelCommand([EComponent.IMAGING_DEVICE]);
      console.log("Cancel command executed, response:", res);

      const cancelCommandResponse = res[0];
      Sentry.captureMessage(JSON.stringify({ cancelCommandResponse }));

      if (cancelCommandResponse.status === "fulfilled") {
        console.log("Cancel command fulfilled, parsing response...");

        const parsedCancelCommandResponse = parseResponse(
          cancelCommandResponse.value.data
        );
        Sentry.captureMessage(JSON.stringify({ parsedCancelCommandResponse }));

        if (parsedCancelCommandResponse.IsSuccess) {
          console.log("Cancel command successful, getting scanner status...");

          const scannerStatusResponse = await getStatus(
            EComponent.IMAGING_DEVICE
          );
          Sentry.captureMessage(JSON.stringify({ scannerStatusResponse }));

          const parsedScannerStatusResponse = parseResponse(
            scannerStatusResponse
          );
          Sentry.captureMessage(
            JSON.stringify({ parsedScannerStatusResponse })
          );

          if (parsedScannerStatusResponse.IsSuccess) {
            console.log("Scanner status successful, starting scan...");

            setIsScanningBarcode(true);
            const scannerScanResponse = await scan(timeoutSeconds * 1000);
            Sentry.captureMessage(JSON.stringify({ scannerScanResponse }));

            const parsedScannerScanResponse =
              parseResponse(scannerScanResponse);
            Sentry.captureMessage(
              JSON.stringify({ parsedScannerScanResponse })
            );

            if ("ResponseData" in parsedScannerScanResponse) {
              console.log(
                "ResponseData key exists in parsedScannerScanResponse:",
                parsedScannerScanResponse.ResponseData
              );
            } else {
              console.log(
                "ResponseData key does NOT exist in parsedScannerScanResponse"
              );
            }

            if (parsedScannerScanResponse.IsSuccess) {
              const data = parsedScannerScanResponse?.ResponseData?.Barcode;
              console.log("Barcode data:", data);

              if (data) {
                Sentry.captureMessage(data);
                navigate(ROUTES.TRANSACTION.path, {
                  state: {
                    purchaseOrderId: data,
                  },
                });
              } else {
                console.error("No barcode data found");
                throw new Error("No data found");
              }
            } else {
              console.error("Scan response was not successful");
              throw new Error("parsedScannerScanResponse is not success");
            }
          } else {
            console.error("Scanner status response was not successful");
            throw new Error("parsedScannerStatusResponse is not success");
          }
        } else {
          console.error("Cancel command response was not successful");
          throw new Error("parsedCancelCommandResponse is not success");
        }
      } else {
        console.error("Cancel command response was not fulfilled");
        throw new Error("cancelCommandResponse is not fulfilled");
      }
    } catch (error) {
      console.error("An error occurred:", error);

      // TODO uncomment this to test transaction screen with data start
      // await navigate(ROUTES.TRANSACTION.path, {
      //   state: {
      //     purchaseOrderId,
      //   },
      // });
      // return;
      // TODO for testing end

      Sentry.captureException(error);
      alert(error);
      window.location.href = ROUTES.OOPS.path;
    }
  }, [navigate]);

  useEffect(() => {
    startScanner();

    // navigate(ROUTES.TRANSACTION.path, {
    //         state: {
    //           purchaseOrderId,
    //         },
    //       });

    // (() => {
    //   (window as any).scan = (purchaseOrderId: string) => {
    //     if (!purchaseOrderId) {
    //       console.log("purchaseOrderId not found");
    //       return;
    //     }

    //     navigate(ROUTES.TRANSACTION.path, {
    //       state: {
    //         purchaseOrderId,
    //       },
    //     });
    //   };
    // })();
  }, [startScanner]);

  return (
    <>
      <Header
        duration={timeoutSeconds}
        startTimer={isScanningBarcode}
        onTimerFinish={onTimerFinish}
      />
      <ScannerBody />
      <Footer />
    </>
  );
}

const timeoutSeconds = 300;

export default ScannerScreen;
