export default function Footer() {
  // const socialIcons = [
  //   "/assets/icons/youtube.png",
  //   "/assets/icons/facebook.png",
  //   "/assets/icons/instagram.png",
  //   "/assets/icons/linkedin.png",
  // ];
  
  // Get current year dynamically
  const currentYear = new Date().getFullYear();
  
  return (
    <>
      <footer className="main-footer-wrape">
        <div className="inner-div">
          <span className="part1-text"></span>
          <div className="part2-text-wrape">
            <span className="part2-text">Copyrights 2010-{currentYear} | PayMore®</span>
            {/* <span className="part2-green-text ">
              <a href="https://paymore.com" target="_blank">
                paymore.com
              </a>
            </span> */}
          </div>
          <div className="part3-wrape">
            <div>
              {/* <div className="part3-upper-txt">
                <a href="https://paymore.com" target="_blank">
                  www.paymore.com
                </a>
              </div>

              <div className="part3-bottom-txt">
                {socialIcons?.map((e, index) => {
                  return (
                    <a href="/" key={index} target="_blank">
                      <img key={index} src={e} alt="social-icon" className="icn-size"></img>
                    </a>
                  );
                })}
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
