import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

function ThankyouBody() {
  return (
    <>
      <div className="main-wrape ">
        <div className="main-inner-wrape">
          <img
            src="/assets/icons/thankyou.png"
            className="absolute h-[964px] w-[909px] bottom-[0px] left-[58px] right-0 top-[102px]"
            alt="thankyou"
          ></img>
          <div className="absolute  left-[160px] right-0 bottom-[0px] top-[1040px] flex w-[667px] ">
            <div className="absolute top-20  font-inter-font text-[32px] font-[400] left-0 text-black leading-[47px]">
              <p className="text-center">
                Thank you for using our service!
              </p>
              <p className="text-center">
                Your transaction has been completed successfully.
              </p>
              <p className="text-center">
                We appreciate your business and hope to see you again.
              </p>
            </div>
          </div>

          <div className="">
            <div className=" gap-4 absolute left-[700px] flex bottom-[38px]">
              <Link to={ROUTES.HOMEPAGE.path}>
                <button className="animate-scaleUp border-[3px] border-white  rounded-[66px] bg-[#357E00] h-[89px] w-[283px] text-white font-inter-font text-[45px] font-[400] " >
                  Close
                </button>
              </Link>
            </div>
          </div>

          <div className="triangle-shap-div-transaction"></div>
        </div>
      </div>
    </>
  );
}

export default ThankyouBody;
