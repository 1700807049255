import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import TransactionBody from "../components/transactionScreen/transactionBody";
import { useCallback, useEffect, useState } from "react";
import { ROUTES } from "../constants/routes";
import Loader from "../components/Loader";
import axios from "axios";
import ErrorComponent from "../components/commonFiles/error";
import * as Sentry from "@sentry/react";

function TransactionScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [popupMessage, setPopupMessage] = useState(""); 
  const [purchaseDetails, setPurchaseDetails] = useState({
    customerName: "",
    remainingAmt: 0,
    products: [],
    transactionId: "",
    dateOfPurchase: "",
    emailAddress: "",
    phoneNumber: "",
    directBills: [],
    totalMachineAmount: 0,
    paidWithinLimit: 0,
    remainingAfterLimit: 0,
    remainingAmount: 0,
    totalPaidAmount: 0,
    dispenceType: ""
  });

  const purchaseOrderId = (location.state as { purchaseOrderId: string })
    ?.purchaseOrderId;

  const fetchPurchaseDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_API_BASE_URL
        }/kiosk/get-purchase/${purchaseOrderId}`
      );

      console.log("response on transaction details page", response?.data);
      Sentry.captureEvent({
        message: "Transaction Details Captured",
        level: "info",
        extra: { response: response?.data },
      });
      const { firstName, lastName, emailAddress, phoneNumber } =
        response.data.data.customers;
      const { remainingAmt, products, transactionId, dateOfPurchase } =
        response.data.data;
      const directBills = response.data.availableBills.bills;
      const totalMachineAmount = response?.data?.totalPayStationBalance || 0;
      const dispenceType = response.data?.availableBills?.dispenceType || "NOT_PAYABLE";

      setPurchaseDetails({
        customerName: `${firstName} ${lastName}`,
        remainingAmt,
        products,
        transactionId,
        dateOfPurchase,
        emailAddress,
        phoneNumber,
        directBills,
        dispenceType,
        totalMachineAmount,
        paidWithinLimit: response.data?.availableBills?.paidWithinLimit || 0,
        remainingAfterLimit: response.data?.availableBills?.remainingAfterLimit || 0,
        remainingAmount: response.data?.availableBills?.remainingAmount || 0,
        totalPaidAmount: response.data?.availableBills?.totalPaidAmount || 0,

      });
      setLoading(false);
    } catch (error: any) {
      if (error.status === 400) {
        setPopupMessage(error.response.data.message); 
        return;
      }
      console.error("Error fetching purchase details:", error);
      navigate(ROUTES.OOPS.path);
    }
  }, [purchaseOrderId, navigate]);

  useEffect(() => {
    if (!purchaseOrderId) {
      console.log("purchaseOrderId not found");
    } else {
      fetchPurchaseDetails();
    }
  }, [fetchPurchaseDetails, purchaseOrderId]);

  const onTimerFinish = useCallback(() => {
    window.location.href = ROUTES.HOMEPAGE.path;
  }, []);

  const onProcessTransaction = useCallback(
    async (remainingAmt: number, bills: any, totalMachineAmount:any) => {

      console.log("remainingAmt on transaction details page", remainingAmt);
      console.log("bills on transaction details page", bills);
      console.log("totalMachineAmount on transaction details page", totalMachineAmount);

      try {
        navigate(ROUTES.TRANSACTIONPROCESS.path, {
          state: {
            name: purchaseDetails.customerName,
            amount: remainingAmt,
            purchaseOrderId,
            bills,
            totalMachineAmount
          },
        });
      } catch (error) {
        console.log("Error", error);
        alert(error);
        window.location.href = ROUTES.HOMEPAGE.path;
      }
    },
    [purchaseDetails.customerName, purchaseOrderId, navigate]
  );

  return (
    <>
      <Header duration={700} startTimer onTimerFinish={onTimerFinish} />
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Loader variant="LARGE" />
        </div>
      ) : (
        <TransactionBody
          name={purchaseDetails.customerName}
          amount={purchaseDetails.remainingAmt}
          products={purchaseDetails.products}
          transactionId={purchaseDetails.transactionId}
          date={purchaseDetails.dateOfPurchase}
          email={purchaseDetails.emailAddress}
          phone={purchaseDetails.phoneNumber.replace(/\D/g, "")}
          purchaseOrderId={purchaseOrderId}
          onProcessTransactionPromise={onProcessTransaction}
          directBills={purchaseDetails.directBills}
          totalMachineAmount={purchaseDetails.totalMachineAmount}
          paidWithinLimit={purchaseDetails.paidWithinLimit}
          remainingAfterLimit={purchaseDetails.remainingAfterLimit}
          remainingAmount={purchaseDetails.remainingAmount}
          totalPaidAmount={purchaseDetails.totalPaidAmount}
          dispenceType={purchaseDetails.dispenceType}
        />
      )}
      <Footer />

      {/* Display the popup when there's a message */}
      {popupMessage && (
        <ErrorComponent
          message={popupMessage}
          onClose={() => {
            setPopupMessage(""); 
            navigate(ROUTES.SPLASH.path); 
          }}
        />
      )}
    </>
  );
}

export default TransactionScreen;